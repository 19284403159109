import { useEffect } from 'react';
import { Box, Image, Center, Link } from '@chakra-ui/react';
import { Header } from '@components';

const RedirectPage = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      // A more elegant solution maybe available to perform this redirect.
      // window.location.href = `https://www.adidas.com/ivypark`;
    }
  }, []);
  return (

    <Center
      maxWidth="50rem"
      mx="auto"
      pt="12.75rem"
      pb="16"
      bg="black"
    >
      <Image
        position="absolute"
        top="6.25rem"
        mx="auto"
        src="/logo.svg"
        alt="adidas | Ivy Park"
        zIndex="2"
      />
      <Box width="100%" textAlign="center">
          <Link href="https://www.adidas.com/ivypark">Visit Adidas Ivy Park</Link>
      </Box>
    </Center>
  );
}

export default RedirectPage;
