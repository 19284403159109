import { useState, useRef, useEffect } from 'react';
import { keyframes } from '@emotion/react';
import {
  Box,
  Button,
  Heading,
  Image,
  Input,
  CircularProgress,
  Center,
  Flex
} from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { GlassBox } from '@components';
import { ProjectorImages } from '@helpers/projector-images';

import { useTranslation } from 'react-i18next';
import cameraButton from '@static/buttons/camera.svg';

const timerLimit = 30;
const apiSubDomain = window.location.hostname.split('.').shift() === 'dev' ? `devproxy` : `proxy`;

const scaleAnimation = keyframes`
  from { transform: scale(0) rotate(-180deg); }
  to { transform: none; }
`;

const Selector = ({ changeBackground, roomId }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [cameraImage, setCameraImage] = useState(null);
  const [canTakeAndSharePicture, setCanTakeAndSharePicture] = useState(false);

  const inputElementRef = useRef(null);

  useEffect(() => {
    setOptions([
      {
        id: 'none',
        src: '/icon-none.svg'
      },
      ...Object.keys(ProjectorImages).map(key => {
        if (key === `image0`) {
          return null;
        }
        return (
          {
            id: key,
            src: ProjectorImages[key].small
          }
        );
      }).filter(item => item !== null),
    ]);

    if (typeof navigator !== 'undefined' && typeof navigator.share !== 'undefined') {
      setCanTakeAndSharePicture(true);
    }
  }, []);

  const previewSliderRef = useRef();
  const [selectedPattern, setSelectedPattern] = useState(0);
  const [timerCount, setTimerCount] = useState(0);
  const progress = 100 / timerLimit * timerCount;

  const onSelect = e => {
    const optionEls = [...e.target.parentNode.parentNode.children];
    const selectedIndex = optionEls.findIndex(el => el === e.target.parentNode);
    if (selectedIndex !== -1) {
      setSelectedPattern(selectedIndex);
    }
  };

  // When the selected pattern changes update the other active content
  // and start a timer to reset the selector when it finishes.
  useEffect(() => {
    previewSliderRef.current.swiper.slideTo(selectedPattern);
    changeBackground(selectedPattern);

    // Send request to change pattern on the device
    const messageBody = { roomId, imageNumber: selectedPattern };

    fetch(`https://${apiSubDomain}.ivyparkss22.click`, {
      method: `POST`,
      body: JSON.stringify(messageBody)
    })
      .then(response => response.json())
      .then(data => console.log(data));

    if (selectedPattern !== 0) {
      const timer = setInterval(() => {
        setTimerCount(prevCount => prevCount + .1);
      }, 100);

      return () => {
        clearInterval(timer);
        setTimerCount(0);
      };
    }
  }, [selectedPattern, changeBackground]);

  // When the timer finishes reset the selector.
  useEffect(() => {
    if (timerCount > timerLimit + 1) {
      setSelectedPattern(0);
    }
  }, [timerCount]);

  const handleTakePicture = ({ inputObj }) => {
    setCameraImage(inputObj.target.files[0]);
  };

  const handleTakeAndShareClick = () => {
    setCameraImage(null);
    inputElementRef.current.click();
  };

  useEffect(() => {
    if (canTakeAndSharePicture === true) {
      navigator.share({
        files: [cameraImage],
      });
    }
  }, [cameraImage]);

  return (
    <Box>
      <Box position="relative" width="18.8rem" mx="auto" mb="6">
        <Image src="/glass-circle.png" width="100%" />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="100%"
          sx={{
            '.swiper-wrapper': {
              alignItems: 'center',
            },
            '.swiper-slide': {
              opacity: 0,
            },
            '.swiper-slide-active': {
              opacity: 1,
            },
            '.swiper-slide-active > *:first-child': {
              animation: `${scaleAnimation} .6s cubic-bezier(0.83, 0, 0.17, 1)`,
            },
          }}
        >
          <Swiper
            modules={[EffectFade]}
            initialSlide={selectedPattern}
            effect="fade"
            fadeEffect={{ crossFade: true }}
            ref={previewSliderRef}
          >
            {options.map(({ id, src }) => (
              <SwiperSlide key={id}>
                <Center width="100%" height="100%">
                  <Image src={src} p="6" borderRadius="50%" />
                </Center>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        {timerCount > 0 &&
          <CircularProgress
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            value={progress}
            size="21.6rem"
            thickness=".175rem"
            color="grey.1"
            trackColor="transparent"
            capIsRound={true}
          />
        }
        {(canTakeAndSharePicture === true) &&
          <Box
            position="absolute"
            top="15%"
            left="85%"
            cursor="pointer"
            onClick={handleTakeAndShareClick}
            borderRadius="20px"
            bgColor="#000"
            opacity="0.7"
          >
            <GlassBox
              borderRadius="20px"
              sx={{ width: '82px', height: '82px' }}
            >
              <Image
                src={cameraButton}
              />
              <Input
                id="cameraFileInput"
                type="file"
                accept="image/*"
                capture="environment"
                onChange={(inputObj) => handleTakePicture({inputObj})}
                display="none"
                ref={inputElementRef}
              />
            </GlassBox>
          </Box>
        }
      </Box>
      <Box>
        <Flex justify="space-between" px="6" mb="6">
          <Heading size="sm">
            {t('common.select')}
          </Heading>
          <Image src="/icon-arrow-right.svg" />
        </Flex>
        <Box
          w="100%"
          sx={{
            '.swiper': {
              px: '6',
            },
            '.swiper-slide .box': {
              opacity: 0.5,
            },
            '.swiper-slide .dot': {
              bg: 'grey.2',
              opacity: 0.2,
            },
            '.swiper-slide .is-active .box': {
              opacity: 1,
            },
            '.swiper-slide .is-active .dot': {
              bg: 'white',
              opacity: 1,
            },
          }}
        >
          <Swiper slidesPerView="auto" spaceBetween={18}>
            {options.map(({ id, src }, index) => (
              <SwiperSlide key={id} style={{ width: '8.25rem' }}>
                <Box
                  as="button"
                  className={selectedPattern === index ? 'is-active' : null}
                  display="block"
                  width="100%"
                  onClick={onSelect}
                >
                  <Box className="box" pointerEvents="none">
                    <GlassBox
                      borderRadius="2.4rem"
                      sx={{ width: '100%', height: '8.25rem' }}
                    >
                      <Image
                        src={src}
                        borderRadius={index !== 0 ? '2.4rem' : null}
                        p={index !== 0 ? '3' : null}
                        pointerEvents="none"
                      />
                    </GlassBox>
                  </Box>
                  <Box
                    className="dot"
                    width=".625rem"
                    height=".625rem"
                    borderRadius="50%"
                    mx="auto"
                    mt="4"
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};

export default Selector;
