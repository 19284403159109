import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  Link,
  useToast,
  Skeleton
} from '@chakra-ui/react';
import { navigate, useParams } from '@reach/router'
import ShortUniqueId from 'short-unique-id';

const dbSubDomain = window.location.hostname.split('.').shift() === 'dev' ? `devdb` : `db`;

const RoomDetailsPage = () => {
  const params = useParams();
  const toast = useToast()
  const [pageAction, setPageAction] = useState(params.roomId);
  const [storeId, setStoreId] = useState(params.storeId);
  const [pageActionButtonText, setPageActionButtonText] = useState(`Add`);
  const [roomDetails, setRoomDetails] = useState(null);
  const [roomId, setRoomId] = useState(params.roomId);
  const [roomName, setRoomName] = useState('');
  const [roomDescription, setRoomDescription] = useState('');
  const [isRoomNameError, setIsRoomNameError] = useState(true);
  const [currentHost, setCurrentHost] = useState(null);
  const [passwordText, setPasswordText] = useState('');
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    // Get password from session.
    const passwordTextFromStorage = sessionStorage.getItem(`loginCredentials`);
    setPasswordText(passwordTextFromStorage);
    if (passwordTextFromStorage === null) {
      navigate(`/ivy-admin`);
    }
    if (pageAction !== `add`) {
      // Get store details and populate form
      setPageActionButtonText(`Update`);

      // Get details of Store.
      fetch(`https://${dbSubDomain}.ivyparkss22.click`, {
        method: `POST`,
        body: JSON.stringify({
          password: passwordTextFromStorage,
          command: `roomGet`,
          payload: {
            roomId: pageAction,
            storeId: storeId
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true) {
            setRoomDetails(data.data.Item);
          } else {
            toast({
              title: `Error retrieving the Store details`,
              description: `Please reload the page but if the problem persists refer to the documentation.`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
          setTimeout(() => {
            setIsPageLoaded(true);
          }, 2000);
        })

    } else {
      setIsPageLoaded(true);
    }
    if (typeof window !== `undefined`) {
      setCurrentHost(window.location.origin);
    }
  }, []);

  useEffect(() => {
    if (typeof roomDetails !== `undefined` && roomDetails !== null) {
      setRoomName(roomDetails.name.S);
      setIsRoomNameError(roomDetails.name.S === ``);
      setRoomDescription(roomDetails.description.S);
    }
  }, [roomDetails]);

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case `room-name`:
        setRoomName(e.target.value);
        setIsRoomNameError(e.target.value === ``);
        break;
        case `room-description`:
          setRoomDescription(e.target.value);
          break;
      default:
        console.error(`Invalid input element`);
    }
  };

  const handleRoomSave = () => {
    // Add/Update store details.
    let command = `roomUpdate`;
    let roomId = pageAction;
    if (pageAction === `add`) {
      command = `roomPut`;
      const uid = new ShortUniqueId({ length: 14, dictionary: `alphanum_lower` });
      roomId = uid();
    }

    // Get details of Store.
    return new Promise((resolve) => {
      fetch(`https://${dbSubDomain}.ivyparkss22.click`, {
        method: `POST`,
        body: JSON.stringify({
          password: passwordText,
          command,
          payload: {
            roomId,
            storeId,
            name: roomName,
            description: roomDescription,
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true) {
            toast({
              title: `Room '${roomName}' ${pageAction === `add` ? `Added` : `Updated`}`,
              description: `The Room details have been ${pageAction === `add` ? `Added` : `Updated`}`,
              status: `info`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          } else {
            toast({
              title: `Error saving Room details`,
              description: `Please reload the page but if the problem persists refer to the documentation.`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
          resolve();
          if (pageAction === `add`) {
            // redirect back to Store page when adding a Room.
            navigate(`/ivy-admin/store-details/${storeId}`);
          }
        })
    });
  };

  return (
    <Center
      p="20px"
      maxWidth="900px"
      mx="auto"
    >
      <Box width="100%">
        <Image mx="auto" mb="12" src="/logo.svg" alt="adidas | Ivy Park" />
        <Heading my="20px">{pageAction === `add` ? `Add Room` : `Update Room Details`}</Heading>
        <Button my="20px" onClick={() => navigate(`/ivy-admin/store-details/${storeId}`)}>Back to Store details page</Button>

        <Skeleton isLoaded={isPageLoaded}>
          <FormControl isRequired mb="20px" isInvalid={isRoomNameError}>
            <FormLabel htmlFor='room-name'>Room name</FormLabel>
            <Input
              id="room-name"
              placeholder="Room name"
              onChange={handleInputChange}
              value={roomName}
            />
            <FormHelperText>
              Enter the name of the Room e.g. 'Deluxe Changing Room'
            </FormHelperText>
            <FormErrorMessage>Room name is required.</FormErrorMessage>
          </FormControl>
        </Skeleton>

        <Skeleton isLoaded={isPageLoaded}>
          <FormControl mb="20px">
            <FormLabel htmlFor='room-description'>Store description</FormLabel>
            <Input
              id="room-description"
              placeholder="Room description"
              onChange={handleInputChange}
              value={roomDescription}
            />
            <FormHelperText>
              Enter the Description of the Room
            </FormHelperText>
          </FormControl>
        </Skeleton>
        <Skeleton isLoaded={isPageLoaded}>
          <Button mr="20px" onClick={() => navigate(`/ivy-admin/store-details/${storeId}`)}>Cancel</Button>
          <Button alignSelf="start" onClick={() => handleRoomSave()} isDisabled={isRoomNameError}>{pageActionButtonText}</Button>
        </Skeleton>
        {pageAction !== `add` &&
          <Skeleton isLoaded={isPageLoaded}>
            <Heading my="20px">Barcode Page</Heading>
            <Link href={`${currentHost}/barcode/${roomId}`} isExternal>
              {currentHost}/barcode/{roomId}
            </Link>
            <Heading my="20px">Display Page</Heading>
            <Link href={`${currentHost}/display/${roomId}`} isExternal>
              {currentHost}/display/{roomId}
            </Link>
          </Skeleton>
        }
      </Box>
    </Center>
  )
}

export default RoomDetailsPage;
