import { Box, Image, Heading, Text } from '@chakra-ui/react'
import { GlassBox, Wrapper } from '@components'

import { useTranslation } from 'react-i18next';

const Timeout = () => {
  const { t } = useTranslation();
  return (
    <Box pt="4rem">
      <Wrapper>
        <GlassBox
          borderRadius="2.375rem"
          moreTransparent={true}
          sx={{
            position: 'relative',
            pt: '4rem',
            pb: '10',
            mx: 'auto',
            width: '100%',
            maxWidth: '20.75rem',
            minHeight: '20rem',
          }}
        >
          <Box w="100%">
            <Image
              position="absolute"
              top="0"
              left="50%"
              transform="translate(-50%, -50%)"
              width="13.5rem"
              src="/welcome-graphic.png"
            />
            <Box mt="6" mb="12" textAlign="center" px="16">
              <Heading size="xl" mb="6" lineHeight="1">
                {t('timeout.header')}
              </Heading>
              <Text lineHeight="1.1" fontSize="sm" color="white">
                {t('timeout.text')}
              </Text>
            </Box>
            <Box px="8">
              <Box
                as="a"
                href="https://adidas.com/ivypark"
                target="_blank"
                rel="nofollow"
                width="100%"
                textTransform="uppercase"
                fontSize="xl"
                fontWeight="bold"
                letterSpacing="inherit"
              >
                <GlassBox
                  backgroundColor="rgba(255, 255, 255, .08)"
                  borderPrimaryColor="rgba(255, 255, 255, .6)"
                  borderSecondaryColor="rgba(163, 160, 160, .6)"
                  sx={{ height: '3rem' }}
                >
                  adidas.com/ivypark
                </GlassBox>
              </Box>
            </Box>
          </Box>
        </GlassBox>
      </Wrapper>
    </Box>
  )
}

export default Timeout
