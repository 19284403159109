import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js/react-native';
import { useParams, Link } from '@reach/router';
import { Box } from '@chakra-ui/react';
import { ProjectorImages } from '@helpers/projector-images';

Pusher.logToConsole = true;

const DisplayPage = () => {
  const params = useParams();
  const [changeImageRequest, setChangeImageRequest] = useState(null);
  const [backGroundImage, setBackGroundImage] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    let pusherID = ``;
    switch (window.location.hostname.split('.').shift()) {
      case `dev`:
      case `localhost`:
        pusherID = process.env.REACT_APP_DEV_CLIENT_PUSHER_ID;
        break;
      default:
        // Defaults to production
        pusherID = process.env.REACT_APP_CLIENT_PUSHER_ID;
        break;
    }
    const pusher = new Pusher(pusherID, {
      cluster: 'eu'
    });

    const channel = pusher.subscribe(`room_${params.roomId}`);
    channel.bind('image_number', (data) => {
      setChangeImageRequest(data);
    });
  }, []);

  useEffect(() => {
    if (changeImageRequest !== null) {
      clearTimeout(timeoutId);
      try {
        const imageNumber = JSON.parse(changeImageRequest.message).imageNumber;
        if(imageNumber > 0) {
          setBackGroundImage(ProjectorImages[`image${imageNumber}`].large);
          // If the current image number is not 0, default, then set a timer to change it to 0 after x time.
          // 180000 milliseconds = 3 minutes.
          setTimeoutId(setTimeout(() => setBackGroundImage(ProjectorImages.image0.large), 180000));
        } else {
          setBackGroundImage(null);
        }
      }
      catch (err) {
        console.log(`Error with imageNumber: `, err);
      }
      finally {
        setChangeImageRequest(null);
      }
    }
  }, [changeImageRequest]);

  return (
      <Box
        width="100%"
        height="100%"
        minHeight="100vh"
        d="flex"
        bgImage={backGroundImage}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgColor="#686362"
      />
  )
}

export default DisplayPage;
