import { Box, Center } from "@chakra-ui/react"

const GlassBox = ({ 
  borderRadius = ".75rem", 
  removeBorderRadiusLeft = false,
  moreTransparent = false,
  sx = {
    width: "3rem",
    height: "3rem"
  },
  children 
}) => {
  const backgroundColor = moreTransparent ? "rgba(255,255,255,.05)" : "rgba(255, 255, 255, .08)"
  const borderPrimaryColor = moreTransparent ? "rgba(255,255,255, .25)" : "rgba(255, 255, 255, .6)"
  const borderSecondaryColor = moreTransparent ? "rgba(255,255,255, 0)" : "rgba(163, 160, 160, .6)"
  return (
    <Center 
      position="relative"
      bg={backgroundColor}
      borderRadius={borderRadius}
      borderLeftRadius={removeBorderRadiusLeft ? "none" : null}
      sx={sx}
      zIndex="1">
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          width="100%"
          height="100%"
          _before={{
            content: '""',
            display: "block",
            position: "absolute",
            top: borderRadius,
            left: "0",
            width: ".0625rem",
            height: `calc(100% - ${borderRadius} - ${borderRadius})`,
            background: `linear-gradient(${borderPrimaryColor} 0%, ${borderSecondaryColor} 100%)`,
            zIndex: 2
          }}
          _after={{
            content: '""',
            display: "block",
            position: "absolute",
            top: borderRadius,
            right: "0",
            width: ".0625rem",
            height: `calc(100% - ${borderRadius} - ${borderRadius})`,
            background: `linear-gradient(${borderPrimaryColor} 0%, ${borderSecondaryColor} 100%)`,
            zIndex: 2
          }}>
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            width="100%"
            height={borderRadius}
            borderColor={borderPrimaryColor}
            borderWidth=".0625rem"
            borderTopLeftRadius={removeBorderRadiusLeft ? 0 : borderRadius}
            borderTopRightRadius={borderRadius}
            borderBottom="none"/>
          <Box
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            width="100%"
            height={borderRadius}
            borderColor={borderSecondaryColor}
            borderWidth=".0625rem"
            borderBottomLeftRadius={removeBorderRadiusLeft ? 0 : borderRadius}
            borderBottomRightRadius={borderRadius}
            borderTop="none"/>  
        </Box>
        {children}
    </Center>
  )
}

export default GlassBox
