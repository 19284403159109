import React, { useEffect, useState } from 'react';
import { AwesomeQRCode } from "@awesomeqr/react";
import { QRCodeSVG } from 'qrcode.react';
import Pusher from 'pusher-js/react-native';
import { useParams } from '@reach/router';
import { Box, Flex } from '@chakra-ui/react';

// Leaving commented so that additional functionality can be shown.
// import iconAdidas from '@static/icons/adidas-icon-180x180.png'
// import barcodeBackground from '@static/icons/face.png'
// import barcodeBackground from '@static/icons/face2.png'
// import barcodeBackground from '@static/icons/face3.jpg'


Pusher.logToConsole = true;

const BarcodePage = () => {
  const params = useParams();
  const [currentHost, setCurrentHost] = useState(null);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setCurrentHost(window.location.origin);
    }
  }, []);

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="#fff"
      justifyContent="center"
      alignItems="center"
    >
      {/* Leaving in so that demo's can be performed */}
      {/* <Box w="300px" h="300px">
      <AwesomeQRCode
        options={{
          text: `${currentHost}/home/${params.roomId}`,
          // Leaving commented so that additional functionality can be shown.
          backgroundImage: barcodeBackground,
          // logoImage: iconAdidas,
        }}
      />
      </Box> */}
      <Box w="75%" h="75%">
        <QRCodeSVG size="100%" value={`${currentHost}/home/${params.roomId}`} />
      </Box>
    </Flex>
  )
}

export default BarcodePage;
