import { Box, HStack, Image, Text } from '@chakra-ui/react';
import { motion } from "framer-motion";
import { Wrapper } from "@components";

import i18n  from '../../../i18n';

const handlerChangeLanguage = ({language}) => i18n.changeLanguage(language);

const LanguageMenu = ({ isActive }) => {
  // May be able to move this to Locise once we get that setup
  // but for now these will be hardcoded.
  const options = [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'de',
      label: 'Deutsch',
    },
  ];

  const activeLanguage = i18n.resolvedLanguage;

  return (
    <motion.div
      animate={isActive ? "show" : "hide"}
      initial="hide"
      variants={{
        show: { opacity: 1, visibility: "visible" },
        hide: { opacity: 0, transitionEnd: { visibility: "hidden" } },
      }}
      transition={{ duration: .3 }}
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "999"
      }}>
      <Box 
        pt="32"
        px="6"
        bgImage="url(/default-bg.jpg)"
        bgSize="cover"
        height="100%">
        <Box overflowY="auto" height="100%" pb="8">
          <Wrapper>
            <Box as="ul" listStyleType="none">
              {options.map(({ value, label }) => (
                <li key={value}>
                  <HStack key={value} as="button" spacing="4" onClick={() => handlerChangeLanguage({language: value})}>
                    <Image
                      src="/icon-tick.svg"
                      opacity={activeLanguage === value ? 1 : 0}
                    />
                    <Text
                      textTransform="uppercase"
                      fontWeight="bold"
                      fontSize="3xl"
                      color={activeLanguage === value ? "grey.1" : "grey.2"}
                      pr="8"
                    >
                      {label}
                    </Text>
                  </HStack>
                </li>
              ))}
            </Box>
          </Wrapper>
        </Box>
      </Box>
    </motion.div>
  );
};

export default LanguageMenu;
