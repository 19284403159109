import React, { useState, useRef, useEffect } from 'react';
import { useParams } from '@reach/router';
import { Box, Image, Center } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { motion } from 'framer-motion';
import { Header, Welcome, Selector, Timeout } from '@components';
import { ProjectorImages } from '@helpers/projector-images';

const HomePage = () => {
  const params = useParams();
  const [activeContent, setActiveContent] = useState('welcome');
  const [roomId, setRoomId] = useState(params.roomId);
  const backgroundSliderRef = useRef();
  const backgrounds = [
    {
      id: 'none',
      src: '/default-bg.jpg'
    },
    ...Object.keys(ProjectorImages).map(key => (
      {
        id: key,
        src: ProjectorImages[key].blurred
      }
    ))
  ];

  const changeBackground = (index) => backgroundSliderRef.current.swiper.slideTo(index);

  useEffect(() => {
    if (activeContent !== 'preview') {
      changeBackground(0);
    }
  });

  return (
    <Center
      minHeight="100vh"
      mx="auto"
      pt="12.75rem"
      pb="16"
      bg="black"
    >
      <Header
        onBack={
          activeContent !== 'welcome' && activeContent !== 'timeout' ? () => setActiveContent('welcome') : null
        }
      />
      <Image
        position="absolute"
        top="6.25rem"
        mx="auto"
        src="/logo.svg"
        alt="adidas | Ivy Park"
        zIndex="2"
      />
      <Box position="relative" width="100%" zIndex="2">
        <motion.div
          key={activeContent}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: .3 }}>
          {activeContent === 'selector' ? (
            <Selector changeBackground={changeBackground} roomId={roomId} />
          ) : activeContent === 'timeout' ? (
            <Timeout />
          ) : (
            <Welcome onForward={() => setActiveContent('selector')} />
          )}
        </motion.div>
      </Box>
      <Box 
        position="absolute" 
        top="0" 
        left="0" 
        right="0" 
        bottom="0"
        sx={{
          '.swiper': {
            width: "100%",
            height: "100%"
          }
        }}
        zIndex="1">
        <Swiper
          modules={[EffectFade]}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          allowTouchMove={false}
          ref={backgroundSliderRef}
        >
          {backgrounds.map(({ id, src }) => (
            <SwiperSlide key={id}>
              <Box width="100%" height="100%">
                <Image src={src} width="100%" height="100%" objectFit="cover" />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Center>
  );
};

export default HomePage;
