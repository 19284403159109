import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router'
import {
  Box,
  Button,
  Image,
  Center,
  Heading,
  Text,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast,
  Skeleton
} from '@chakra-ui/react';

const dbSubDomain = window.location.hostname.split('.').shift() === 'dev' ? `devdb` : `db`;

const StoreListPage = () => {
  const toast = useToast();
  const [storeData, setStoreData] = useState(StoreData);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    // Get password from session.
    const passwordText = sessionStorage.getItem(`loginCredentials`);
    if (passwordText === null) {
      navigate(`/ivy-admin`);
    }

    // Get list of stores.
    fetch(`https://${dbSubDomain}.ivyparkss22.click`, {
      method: `POST`,
      body: JSON.stringify({ password: passwordText, command: `storeList` })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          setStoreData(data);
        } else {
          toast({
            title: `Error returning list of Stores`,
            description: `Please reload the page but if problem persists refer to the documentation.`,
            status: `error`,
            duration: 5000,
            isClosable: true,
            position: `top`
          })
        }
        setTimeout(() => {
          setIsPageLoaded(true);
        }, 2000);
      })
  }, [])

  return (
    <Center
      p="20px"
      maxWidth="900px"
      mx="auto"
    >
      <Box width="100%">
        <Image mx="auto" mb="12" src="/logo.svg" alt="adidas | Ivy Park" />
        <Heading>Store List</Heading>
        <Skeleton isLoaded={isPageLoaded}>
          <Button my="20px" onClick={() => navigate(`/ivy-admin/store-details/add`)}>Add Store</Button>
        </Skeleton>
        <StoreList storeData={storeData} isPageLoaded={isPageLoaded} />
      </Box>
    </Center>
  )
}

const StoreList = ({ storeData, isPageLoaded }) => {
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [selectedStoreName, setSelectedStoreName] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();

  if (storeData.data.Count === 0) {
    return (
      <Text>There are no Stores</Text>
    );
  }
  
  const handleStoreDelete = ({ storeId, storeName }) => {
    setSelectedStoreId(storeId);
    setSelectedStoreName(storeName);
    onOpen();
  };

  const handleStoreDeleteConfirm = () => {
    // Delete store and associated rooms.
    onClose();
    toast({
      title: `Store '${selectedStoreName}' deleted.`,
      description: `The store and associated rooms have been deleted.`,
      status: `info`,
      duration: 5000,
      isClosable: true,
      position: `top`
    })
  };

  return (
    <>
      <Skeleton isLoaded={isPageLoaded}>
        <TableContainer w="100%">
          <Table variant='simple'>
            <TableCaption>Store list</TableCaption>
            <Thead>
              <Tr>
                <Th fontSize={`20px`}>Store Name</Th>
                <Th fontSize={`20px`}>Store Contact</Th>
                {/* <Th>&nbsp;</Th> */}
                <Th>&nbsp;</Th>
              </Tr>
            </Thead>
            <Tbody>
              {storeData.data.Items.map((store, index) => {
                const keyValue = store.name.S + index;
                return (
                  <Tr key={keyValue}>
                    <Td><p>{store.name.S}</p></Td>
                    <Td>{store.contact.S}</Td>
                    {/* <Td textAlign="right"><Button onClick={() => handleStoreDelete({ storeId: store.id, storeName: store.name })}>Delete</Button></Td> */}
                    <Td textAlign="right"><Button onClick={() => navigate(`/ivy-admin/store-details/${store.id.S}`)}>Edit</Button></Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Skeleton>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Store '{selectedStoreName}'
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => handleStoreDeleteConfirm()} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

const StoreData = {
  "data": {
    "$metadata": {
      "httpStatusCode": 200,
      "requestId": "Q02HF9K6396QPEGHM7DG1PMVK7VV4KQNSO5AEMVJF66Q9ASUAAJG",
      "attempts": 1,
      "totalRetryDelay": 0
    },
    "Count": 1,
    "Items": [
      {
        "address": {
          "S": "r egre gre g. ger"
        },
        "id": {
          "S": "KSA4YSMO4BXM"
        },
        "country": {
          "S": ""
        },
        "name": {
          "S": "Barry"
        },
        "contact": {
          "S": "er ger gre g"
        }
      }
    ],
    "ScannedCount": 1
  }
}

export default StoreListPage;
