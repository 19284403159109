import ProjectorImage0 from '@static/projector-images/image0.gif';
import ProjectorImage1 from '@static/projector-images/image1.png';
import ProjectorImage2 from '@static/projector-images/image2.png';
import ProjectorImage3 from '@static/projector-images/image3.png';
import ProjectorImage4 from '@static/projector-images/image4.png';
import ProjectorImage0Small from '@static/projector-images/image0-small.jpg';
import ProjectorImage1Small from '@static/projector-images/image1-small.jpg';
import ProjectorImage2Small from '@static/projector-images/image2-small.jpg';
import ProjectorImage3Small from '@static/projector-images/image3-small.jpg';
import ProjectorImage4Small from '@static/projector-images/image4-small.jpg';
import ProjectorImage0Blurred from '@static/projector-images/image0-blurred.jpg';
import ProjectorImage1Blurred from '@static/projector-images/image1-blurred.jpg';
import ProjectorImage2Blurred from '@static/projector-images/image2-blurred.jpg';
import ProjectorImage3Blurred from '@static/projector-images/image3-blurred.jpg';
import ProjectorImage4Blurred from '@static/projector-images/image4-blurred.jpg';

export const ProjectorImages =
{
  image0: {
    'large': ProjectorImage0,
    'small': ProjectorImage0Small,
    'blurred': ProjectorImage0Blurred,
    'icon': 'SM',
  },
  image1: {
    'large': ProjectorImage1,
    'small': ProjectorImage1Small,
    'blurred': ProjectorImage1Blurred,
    'icon': 'SM',
  },
  image2: {
    'large': ProjectorImage2,
    'small': ProjectorImage2Small,
    'blurred': ProjectorImage2Blurred,
    'icon': 'SM',
  },
  image3: {
    'large': ProjectorImage3,
    'small': ProjectorImage3Small,
    'blurred': ProjectorImage3Blurred,
    'icon': 'SM',
  },
  image4: {
    'large': ProjectorImage4,
    'small': ProjectorImage4Small,
    'blurred': ProjectorImage4Blurred,
    'icon': 'SM',
  },
};
