import React from 'react';
import { Router } from '@reach/router';
import Helmet from 'react-helmet';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './styles/theme';
import { Fonts } from './styles/fonts';

import RedirectPage from '@pages/Redirect';
import HomePage from '@pages/Home';
import DisplayPage from '@pages/Display';
import BarcodePage from '@pages/Barcode';
import LoginPage from '@pages/admin/Login'
import StoreListPage from '@pages/admin/StoreList';
import StoreDetailsPage from '@pages/admin/StoreDetails';
import RoomDetailsPage from '@pages/admin/RoomDetails';
import StatsPage from '@pages/admin/Stats';


function App() {
  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <title>Adidas - Ivy Park</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <Fonts />
      <Router>
        <RedirectPage default />
        <HomePage path="home/:roomId" />
        <DisplayPage path="display/:roomId" />
        <BarcodePage path="barcode/:roomId" />
        <LoginPage path="ivy-admin" />
        <StoreListPage path="ivy-admin/store-list" />
        <StoreDetailsPage path="ivy-admin/store-details/:storeId" />
        <RoomDetailsPage path="ivy-admin/room-details/:storeId/:roomId" />
        <StoreListPage path="ivy-admin/store-list" />
        <StatsPage path="ivy-admin/stats" />
        
      </Router>
    </ChakraProvider>
  );
}

export default App;
