import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router'
import {
  Box,
  Button,
  Image,
  Center,
  Heading,
  Text,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  useToast,
  Skeleton
} from '@chakra-ui/react';

const proxySubDomain = window.location.hostname.split('.').shift() === 'dev' ? `devproxy` : `proxy`;

const StatsPage = () => {
  const toast = useToast();
  const [statsData, setStatsData] = useState();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    // Get password from session.
    const passwordText = sessionStorage.getItem(`loginCredentials`);
    if (passwordText === null) {
      navigate(`/ivy-admin`);
    }

    // Get list of stores.
    fetch(`https://${proxySubDomain}.ivyparkss22.click`, {
      method: `POST`,
      body: JSON.stringify({ password: passwordText, fetchResults: 1 })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          setStatsData(data);
        } else {
          toast({
            title: `Error returning Stats`,
            description: `Please reload the page but if problem persists refer to the documentation.`,
            status: `error`,
            duration: 5000,
            isClosable: true,
            position: `top`
          })
        }
        setTimeout(() => {
          setIsPageLoaded(true);
        }, 2000);
      })
  }, [])

  return (
    <Center
      p="20px"
      maxWidth="900px"
      mx="auto"
    >
      <Box width="100%">
        <Image mx="auto" mb="12" src="/logo.svg" alt="adidas | Ivy Park" />
        <Heading>Usage Stats</Heading>
        <Skeleton isLoaded={isPageLoaded}>
          <Button my="20px" onClick={() => navigate(`/ivy-admin/store-list`)}>Back to Stores List</Button>
        </Skeleton>
        {(typeof statsData !== `undefined`) &&
          <Displaystats statsData={statsData} isPageLoaded={isPageLoaded} />
        }
      </Box>
    </Center>
  )
}

const Displaystats = ({ statsData, isPageLoaded }) => {
  console.log(`statsData: `, statsData);
  return (
    <Skeleton isLoaded={isPageLoaded}>
        <TableContainer w="100%">
          <Table variant='simple'>
            <TableCaption>Stats</TableCaption>
            <Thead>
              <Tr>
                <Th fontSize="20px" textAlign="center">Image 1</Th>
                <Th fontSize="20px" textAlign="center">Image 2</Th>
                <Th fontSize="20px" textAlign="center">Image 3</Th>
                <Th fontSize="20px" textAlign="center">Image 4</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td><Text fontSize="4em" lineHeight="1em" textAlign="center">{statsData.stats.image1.count}</Text></Td>
                <Td><Text fontSize="4em" lineHeight="1em" textAlign="center">{statsData.stats.image2.count}</Text></Td>
                <Td><Text fontSize="4em" lineHeight="1em" textAlign="center">{statsData.stats.image3.count}</Text></Td>
                <Td><Text fontSize="4em" lineHeight="1em" textAlign="center">{statsData.stats.image4.count}</Text></Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
    </Skeleton>
  )
}

export default StatsPage;
