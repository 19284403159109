import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast,
  Skeleton
} from '@chakra-ui/react';
import { navigate, useParams } from '@reach/router';
import ShortUniqueId from 'short-unique-id';

const dbSubDomain = window.location.hostname.split('.').shift() === 'dev' ? `devdb` : `db`;

const StoreDetailsPage = () => {
  const params = useParams();
  const toast = useToast()
  const [pageAction, setPageAction] = useState(params.storeId);
  const [pageActionButtonText, setPageActionButtonText] = useState(`Add`);
  const [storeDetails, setStoreDetails] = useState(null);
  const [roomsData, setRoomsData] = useState(null);
  const [storeName, setStoreName] = useState('');
  const [isStoreNameError, setIsStoreNameError] = useState(true);
  const [storeContactName, setStoreContactName] = useState('');
  const [isStoreContactError, setIsStoreContactError] = useState(true);
  const [storeAddress, setStoreAddress] = useState('');
  const [isStoreAddressError, setIsStoreAddressError] = useState(true);
  const [passwordText, setPasswordText] = useState('');
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    // Get password from session.
    const passwordTextFromStorage = sessionStorage.getItem(`loginCredentials`);
    setPasswordText(passwordTextFromStorage);
    if (passwordTextFromStorage === null) {
      navigate(`/ivy-admin`);
    }

    if (pageAction !== `add`) {
      setPageActionButtonText(`Update`);
      // Get details of Store.
      fetch(`https://${dbSubDomain}.ivyparkss22.click`, {
        method: `POST`,
        body: JSON.stringify({
          password: passwordTextFromStorage,
          command: `storeGet`,
          payload: {
            storeId: pageAction
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true) {
            setStoreDetails(data.data.Item);
          } else {
            toast({
              title: `Error retrieving the Store details`,
              description: `Please reload the page but if the problem persists refer to the documentation.`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
        })

      // Get list of rooms.
      fetch(`https://${dbSubDomain}.ivyparkss22.click`, {
        method: `POST`,
        body: JSON.stringify({
          password: passwordTextFromStorage,
          command: `roomList`,
          payload: {
            storeId: pageAction
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true) {
            setRoomsData(data);
          } else {
            toast({
              title: `Error returning list of Rooms`,
              description: `Please reload the page but if problem persists refer to the documentation.`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
        })
    } else {
      setIsPageLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (typeof storeDetails !== `undefined` && storeDetails !== null) {
      setStoreName(storeDetails.name.S);
      setIsStoreNameError(storeDetails.name.S === ``);
      setStoreContactName(storeDetails.contact.S);
      setIsStoreContactError(storeDetails.contact.S === ``);
      setStoreAddress(storeDetails.address.S);
      setIsStoreAddressError(storeDetails.address.S === ``);
    }
    setTimeout(() => {
      setIsPageLoaded(true)
    }, 2000);
  }, [storeDetails]);

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case `store-name`:
        setStoreName(e.target.value);
        setIsStoreNameError(e.target.value === ``);
        break;
      case `store-contact`:
        setStoreContactName(e.target.value);
        setIsStoreContactError(e.target.value === ``);
        break;
      case `store-address`:
        setStoreAddress(e.target.value);
        setIsStoreAddressError(e.target.value === ``);
        break;
      default:
        console.error(`Invalid input element`);
    }
  };

  const handleStoreSave = () => {
    // Add/Update store details.
    let command = `storeUpdate`;
    let storeId = pageAction;
    if (pageAction === `add`) {
      command = `storePut`;
      const uid = new ShortUniqueId({ length: 14, dictionary: `alphanum_lower` });
      storeId = uid();
    }

    // Get details of Store.
    return new Promise((resolve) => {
      fetch(`https://${dbSubDomain}.ivyparkss22.click`, {
        method: `POST`,
        body: JSON.stringify({
          password: passwordText,
          command,
          payload: {
            storeId,
            name: storeName,
            contact: storeContactName,
            address: storeAddress,
            country: ``
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true) {
            toast({
              title: `Store '${storeName}' ${pageAction === `add` ? `Added` : `Updated`}`,
              description: `The store details have been ${pageAction === `add` ? `Added` : `Updated`}`,
              status: `info`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          } else {
            toast({
              title: `Error saving Store details`,
              description: `Please reload the page but if the problem persists refer to the documentation.`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
          resolve();
          if (pageAction === `add`) {
            // redirect back to Store page when adding a Room.
            navigate(`/ivy-admin/store-list`);
          }
        })
    });
  };

  return (
    <Center
      p="20px"
      maxWidth="900px"
      mx="auto"
    >
      <Box width="100%">
        <Image mx="auto" mb="12" src="/logo.svg" alt="adidas | Ivy Park" />
        <Heading my="20px">{pageAction === `add` ? `Add Store` : `Update Store Details`}</Heading>
        <Button my="20px" onClick={() => navigate(`/ivy-admin/store-list`)}>Back to Stores page</Button>

        <Skeleton isLoaded={isPageLoaded}>
          <FormControl isRequired mb="20px" isInvalid={isStoreNameError}>
            <FormLabel htmlFor='store-name'>Store name</FormLabel>
            <Input
              id="store-name"
              placeholder="Store name"
              onChange={handleInputChange}
              value={storeName}
            />
            <FormHelperText>
              Enter the name of the Store e.g. 'UK - Flagship Store London'
            </FormHelperText>
            <FormErrorMessage>Store name is required.</FormErrorMessage>
          </FormControl>
        </Skeleton>

        <Skeleton isLoaded={isPageLoaded}>
          <FormControl isRequired mb="20px" isInvalid={isStoreContactError}>
            <FormLabel htmlFor='store-contact'>Store contact</FormLabel>
            <Input
              id="store-contact"
              placeholder="Store contact"
              onChange={handleInputChange}
              value={storeContactName}
            />
            <FormHelperText>
              Enter the name of the Store Contact e.g. 'Frank Dassler'
            </FormHelperText>
            <FormErrorMessage>Store contact is required.</FormErrorMessage>
          </FormControl>
        </Skeleton>

        <Skeleton isLoaded={isPageLoaded}>
          <FormControl isRequired mb="20px" isInvalid={isStoreAddressError}>
            <FormLabel htmlFor='store-address'>Store address</FormLabel>
            <Input
              id="store-address"
              placeholder="Store address"
              onChange={handleInputChange}
              value={storeAddress}
            />
            <FormHelperText>
              Enter the address of the Store
            </FormHelperText>
            <FormErrorMessage>Store address is required.</FormErrorMessage>
          </FormControl>
        </Skeleton>

        <Skeleton isLoaded={isPageLoaded}>
          <Button mr="20px" onClick={() => navigate(`/ivy-admin/store-list`)}>Cancel</Button>
          <Button
            type="submit"
            alignSelf="start"
            onClick={() => handleStoreSave()}
            isDisabled={isStoreNameError || isStoreContactError || isStoreAddressError}>
            {pageActionButtonText}
          </Button>
        </Skeleton>
        {pageAction !== `add` && <RoomList roomData={roomsData} storeId={pageAction} isPageLoaded={isPageLoaded} />}
      </Box>
    </Center>
  )
}

const RoomList = ({ roomData, storeId, isPageLoaded }) => {
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [selectedRoomName, setSelectedRoomName] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();

  const handleRoomDelete = ({ roomId, roomName }) => {
    setSelectedRoomId(roomId);
    setSelectedRoomName(roomName);
    onOpen();
  }

  const handleRoomDeleteConfirm = () => {
    // Delete store and associated rooms.
    onClose();
    toast({
      title: `Room '${selectedRoomName}' deleted.`,
      description: `The room has been deleted.`,
      status: `info`,
      duration: 5000,
      isClosable: true,
      position: `top`
    })
  }

  return (
    <>
      <Heading mt="40px" mb="20px">Room list</Heading>
      <Button my="20px" onClick={() => navigate(`/ivy-admin/room-details/${storeId}/add`)}>Add Room</Button>
      {roomData?.data?.Count > 0 ?
        <Skeleton isLoaded={isPageLoaded}>
          <TableContainer w="100%">
            <Table variant='simple'>
              <TableCaption>Room list</TableCaption>
              <Thead>
                <Tr>
                  <Th fontSize={`20px`}>Room Name</Th>
                  {/* <Th>&nbsp;</Th> */}
                  <Th>&nbsp;</Th>
                </Tr>
              </Thead>
              <Tbody>

                {roomData.data.Items.map((room, index) => {
                  const keyValue = room.name + index;
                  return (
                    <Tr key={keyValue} width="100%">
                      <Td><p>{room.name.S}</p></Td>
                      {/* <Td textAlign="right"><Button onClick={() => handleRoomDelete({ roomId: room.id, roomName: room.name })}>Delete</Button></Td> */}
                      <Td textAlign="right"><Button onClick={() => navigate(`/ivy-admin/room-details/${storeId}/${room.id.S}`)}>Edit</Button></Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Skeleton>
        :
        <Text>There are no Rooms</Text>
      }
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Room '{selectedRoomName}'
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => handleRoomDeleteConfirm()} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default StoreDetailsPage;
