import { useEffect, useState } from 'react';
import { Box, Flex, HStack, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { GlassBox, LanguageMenu, Wrapper } from '@components';

import { useTranslation } from 'react-i18next';

const Header = ({ onBack }) => {
  const { t } = useTranslation();
  const [languageMenuActive, setLanguageMenuActive] = useState(false);

  useEffect(() => {
    document.body.style.position = languageMenuActive ? 'fixed' : null;
  });

  const showBack = typeof onBack === 'function' && !languageMenuActive;

  return (
    <>
      <Box position="absolute" top="8" left="0" right="0" zIndex="1000">
        <Wrapper>
          <Flex
            mx="auto"
            justify="space-between"
          >
            <motion.div
              animate={showBack ? 'show' : 'hide'}
              variants={{
                show: { opacity: 1 },
                hide: { opacity: 0 },
              }}
              transition={{ duration: 0.3 }}
            >
              {showBack && (
                <HStack
                  as="button"
                  spacing="3"
                  letterSpacing="inherit"
                  textTransform="uppercase"
                  fontSize="xs"
                  onClick={onBack}
                >
                  <Box flex="0">
                    <GlassBox moreTransparent={true}>
                      <Image src="/icon-arrow-left.svg" alt="Back" />
                    </GlassBox>
                  </Box>
                  <Box>{t('common.back')}</Box>
                </HStack>
              )}
            </motion.div>
            <HStack
              as="button"
              spacing="3"
              marginLeft="auto"
              letterSpacing="inherit"
              textTransform="uppercase"
              fontSize="xs"
              onClick={() => setLanguageMenuActive(!languageMenuActive)}
            >
              <Box>{languageMenuActive ? t('common.close') : t('common.language')}</Box>
              <Box flex="0">
                <GlassBox moreTransparent={true}>
                  <Image src="/icon-language.svg" alt={t('common.language')} />
                </GlassBox>
              </Box>
            </HStack>
          </Flex>
        </Wrapper>
      </Box>
      <LanguageMenu isActive={languageMenuActive} />
    </>
  );
};

export default Header;
