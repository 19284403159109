import React, { useState } from 'react';
import { navigate } from '@reach/router'
import {
  Box,
  Button,
  Heading,
  Flex,
  Stack,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  chakra,
  Image,
  useToast,
} from '@chakra-ui/react';

import { FaLock } from "react-icons/fa";

const CFaLock = chakra(FaLock);


const LoginPage = () => {
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [passwordText, setPasswordText] = useState(``);

  const handleShowPasswordClick = () => setShowPassword(!showPassword);

  const handlePasswordChange = (e) => setPasswordText(e.target.value);


  const handleLogin = () => {
    // Login logic goes here
    setIsFormSubmitting(true);

    const apiSubDomain = window.location.hostname.split('.').shift() === 'dev' ? `devauth` : `auth`;

    return new Promise((resolve) => {
      fetch(`https://${apiSubDomain}.ivyparkss22.click`, {
        method: `POST`,
        body: JSON.stringify({ password: passwordText })
      })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          if (data.success === true) {
            sessionStorage.setItem(`loginCredentials`, passwordText);
            navigate(`/ivy-admin/store-list`);
          } else {
            toast({
              title: `Login failed`,
              description: data.error,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
          setIsFormSubmitting(false);
          resolve();
        })
    });
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image mx="auto" mb="12" src="/logo.svg" alt="adidas | Ivy Park" />
        <Heading>Welcome</Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          <Stack
            spacing={4}
            p="1rem"
            boxShadow="md"
          >
            <FormControl>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<CFaLock color="gray.300" />}
                />
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={handlePasswordChange}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleShowPasswordClick}>
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button
              type="submit"
              width="full"
              onClick={() => handleLogin()}
              isDisabled={passwordText === ``}
            >
              Login
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  )
}

export default LoginPage;
