import { Box, HStack, VStack, Image, Heading, Text } from '@chakra-ui/react'
import { GlassBox, Wrapper } from '@components'

import { useTranslation } from 'react-i18next';

const Welcome = ({ onForward }) => {
  const { t } = useTranslation();

  return (
    <Box pt="4rem">
      <Wrapper>
        <GlassBox
          borderRadius="2.375rem"
          moreTransparent={true}
          sx={{
            position: 'relative',
            pt: '4rem',
            pb: '10',
            mx: 'auto',
            width: '100%',
            maxWidth: '20.75rem',
            minHeight: '20rem',
          }}
        >
          <Box w="100%">
            <Image
              position="absolute"
              top="0"
              left="50%"
              transform="translate(-50%, -50%)"
              width="13.5rem"
              src="/welcome-graphic.png"
            />
            <VStack spacing="8" my="8">
              <HStack
                spacing="8"
                position="relative"
                w="100%"
                alignItems="flex-start"
              >
                <Box flex="0">
                  <GlassBox removeBorderRadiusLeft={true}>
                    <Image
                      src="/icon-select-pattern.svg"
                      alt={t('welcome.select.heading')}
                    />
                  </GlassBox>
                </Box>
                <Box width="100%" textAlign="left" pr="10">
                  <Heading size="sm" mb="1">
                    {t('welcome.select.heading')}
                  </Heading>
                  <Text lineHeight="1.1" fontSize="sm" color="grey.2">
                    {t('welcome.select.text')}
                  </Text>
                </Box>
                <Image
                  position="absolute"
                  top="50%"
                  right="0"
                  transform="translateY(-50%)"
                  src="/bar.svg"
                />
              </HStack>
              <HStack
                spacing="8"
                position="relative"
                w="100%"
                alignItems="flex-start"
              >
                <Box flex="0">
                  <GlassBox removeBorderRadiusLeft={true}>
                    <Image src="/icon-try-on.svg" alt="Try on" />
                  </GlassBox>
                </Box>
                <Box width="100%" textAlign="left" pr="10">
                  <Heading size="sm" mb="1">
                  {t('welcome.try.heading')}
                  </Heading>
                  <Text lineHeight="1.1" fontSize="sm" color="grey.2">
                    {t('welcome.try.text')}
                  </Text>
                </Box>
                <Image
                  position="absolute"
                  top="50%"
                  right="0"
                  transform="translateY(-50%)"
                  src="/bar.svg"
                />
              </HStack>
              <HStack
                spacing="8"
                position="relative"
                w="100%"
                alignItems="flex-start"
              >
                <Box flex="0">
                  <GlassBox removeBorderRadiusLeft={true}>
                    <Image src="/icon-share-outfit.svg" alt="Share your outfit" />
                  </GlassBox>
                </Box>
                <Box width="100%" textAlign="left" pr="10">
                  <Heading size="sm" mb="1">
                  {t('welcome.share.heading')}
                  </Heading>
                  <Text lineHeight="1.1" fontSize="sm" color="grey.2">
                    {t('welcome.share.text')}
                  </Text>
                </Box>
                <Image
                  position="absolute"
                  top="50%"
                  right="0"
                  transform="translateY(-50%)"
                  src="/bar.svg"
                />
              </HStack>
            </VStack>
            <Box px="8">
              <Box
                as="button"
                width="100%"
                textTransform="uppercase"
                fontSize="xl"
                fontWeight="bold"
                letterSpacing="inherit"
                onClick={onForward}
              >
                <GlassBox
                  backgroundColor="rgba(255, 255, 255, .08)"
                  borderPrimaryColor="rgba(255, 255, 255, .6)"
                  borderSecondaryColor="rgba(163, 160, 160, .6)"
                  sx={{ height: '3rem' }}
                >
                  {t('common.getStarted')}
                </GlassBox>
              </Box>
            </Box>
          </Box>
        </GlassBox>
      </Wrapper>
    </Box>
  )
}

export default Welcome
